import Deck01 from "/src/assets/deck/page01.svg"
import Deck02 from "/src/assets/deck/page02.svg"
import Deck03 from "/src/assets/deck/page03.svg"
import Deck04 from "/src/assets/deck/page04.svg"
import Deck05 from "/src/assets/deck/page05.svg"
import Deck06 from "/src/assets/deck/page06.svg"
import Deck07 from "/src/assets/deck/page07.svg"
import Deck08 from "/src/assets/deck/page08.svg"
import Deck09 from "/src/assets/deck/page09.svg"
import Deck10 from "/src/assets/deck/page10.svg"
import Deck11 from "/src/assets/deck/page11.svg"
import Deck12 from "/src/assets/deck/page12.svg"
import Deck13 from "/src/assets/deck/page13.svg"
import Deck14 from "/src/assets/deck/page14.svg"
import Deck15 from "/src/assets/deck/page15.svg"
import Deck16 from "/src/assets/deck/page16.svg"
import Deck17 from "/src/assets/deck/page17.svg"
import Deck18 from "/src/assets/deck/page18.svg"
import React from "react";

export const Slides: Array<any> = [
    <Deck01 />,
    <Deck02 />,
    <Deck03 />,
    <Deck04 />,
    <Deck05 />,
    <Deck06 />,
    <Deck07 />,
    <Deck08 />,
    <Deck09 />,
    <Deck10 />,
    <Deck11 />,
    <Deck12 />,
    <Deck13 />,
    <Deck14 />,
    <Deck15 />,
    <Deck16 />,
    <Deck17 />,
    <Deck18 />,
]