import Carousel from "../../components/misc/carousel";
import React from "react";
import {Slides} from "../../components/deck/deck";

export default function DeckPage() {

  return <Carousel className="w-10/12 max-w-screen-2xl h-auto m-auto p-0"
                   showArrows={true}
                   components={Slides}></Carousel>

}
